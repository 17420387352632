import { render, staticRenderFns } from "./academic_font_management_home.vue?vue&type=template&id=658e9737&scoped=true&"
import script from "./academic_font_management_home.vue?vue&type=script&lang=js&"
export * from "./academic_font_management_home.vue?vue&type=script&lang=js&"
import style0 from "./academic_font_management_home.vue?vue&type=style&index=0&id=658e9737&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "658e9737",
  null
  
)

export default component.exports