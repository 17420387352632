<!-- 字库首页 -->
<template>
    <div>
        <!-- 出题系统工具 -->
        <el-card style="margin-top: 15px">
            <el-row class="title_row">
                <el-col>
                    <span class="title_class">词库资源</span>
                </el-col>
            </el-row>
            <el-row :gutter="30" style="margin-top: 34px">
                <el-col
                    :span="4"
                    v-for="(itemData, index) of cikuList"
                    v-bind:key="index"
                >
                    <gameToolBackView
                        :itemData="itemData"
                        :key="index"
                        @itemClicked="racingToolClicked"
                    ></gameToolBackView>
                </el-col>
            </el-row>
        </el-card>
        <!-- 出题系统工具 -->
        <el-card style="margin-top: 15px">
            <el-row class="title_row">
                <el-col>
                    <span class="title_class">出题系统</span>
                </el-col>
            </el-row>
            <el-row :gutter="30" style="margin-top: 34px">
                <el-col
                    :span="4"
                    v-for="(itemData, index) of racingToolList"
                    v-bind:key="index"
                >
                    <gameToolBackView
                        :itemData="itemData"
                        :key="index"
                        @itemClicked="racingToolClicked"
                    ></gameToolBackView>
                </el-col>
            </el-row>
        </el-card>
        <!-- 出题系统工具 -->
        <el-card style="margin-top: 15px">
            <el-row class="title_row">
                <el-col>
                    <span class="title_class">工具管理</span>
                </el-col>
            </el-row>
            <el-row :gutter="30" style="margin-top: 34px">
                <el-col
                    :span="4"
                    v-for="(itemData, index) of toolManageList"
                    v-bind:key="index"
                >
                    <gameToolBackView
                        :itemData="itemData"
                        :key="index"
                        @itemClicked="toolManageClicked"
                    ></gameToolBackView>
                </el-col>
            </el-row>
        </el-card>
        <el-row>
            <el-col :span="24">
                <el-card style="margin-top: 15px">
                    <el-row class="title_row">
                        <el-col>
                            <span class="title_class">词库数据统计</span>
                        </el-col>
                    </el-row>
                    <academicFontData ref="dataRef"></academicFontData>
                </el-card>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-card style="margin-top: 15px">
                    <el-row class="title_row">
                        <el-col :span="12">
                            <span class="title_class">标签管理</span>
                        </el-col>
                        <el-col :span="12" style="text-align: right">
                            <el-button
                                type="text"
                                style="padding: 0"
                                @click="handleToTags()"
                                >查看全部</el-button
                            >
                        </el-col>
                    </el-row>
                    <el-row :gutter="20">
                        <el-col
                            :span="3"
                            v-for="(item, index) in tags"
                            :key="index"
                        >
                            <div
                                class="tag"
                                @click="handleToTags(item.tagName)"
                            >
                                <div class="tag-icon">
                                    <i class="icon iconfont icon-star-full"></i>
                                </div>
                                <div class="tag-title">{{ item.tagName }}</div>
                            </div>
                        </el-col>
                        <!-- <el-col :span="3">
              <div class="tag tag-define" @click="addTagFn">
                <div class="tag-icon"><i class="icon iconfont icon-star-full"></i></div>
                <div class="tag-title"><i class="el-icon-plus"></i> 自定义</div>
              </div>
            </el-col> -->
                    </el-row>
                </el-card>
            </el-col>
        </el-row>
        <el-row :gutter="16">
            <el-col :span="12">
                <el-card style="margin-top: 15px">
                    <el-row class="title_row">
                        <el-col>
                            <span class="title_class">词频排行榜</span>
                        </el-col>
                    </el-row>

                    <el-table
                        :data="marketingRankList"
                        style="width: 100%"
                        size="small"
                    >
                        <el-table-column
                            prop="rankStr"
                            label="排名"
                            width="100"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="districtName"
                            label="单词"
                            width="140"
                        >
                            <template scope="scope">
                                <div class="table-row1">
                                    {{ scope.row.districtName }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="频率" prop="consider">
                            <template scope="scope">
                                <el-progress
                                    type="line"
                                    :percentage="
                                        (scope.row.consider / 10000) * 100
                                    "
                                    :format="format(scope.row, scope.column)"
                                    color="#2db8f8"
                                    :text-inside="false"
                                />
                            </template>
                        </el-table-column>
                    </el-table>
                </el-card>
            </el-col>
            <el-col :span="12">
                <el-card style="margin-top: 15px">
                    <el-row class="title_row">
                        <el-col>
                            <span class="title_class">音节排行榜</span>
                        </el-col>
                    </el-row>
                    <el-table
                        :data="marketingRankList"
                        style="width: 100%"
                        size="small"
                    >
                        <el-table-column
                            prop="rankStr"
                            label="排名"
                            width="100"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="districtName"
                            label="音节"
                            width="140"
                        >
                        </el-table-column>
                        <el-table-column label="单词量占比" prop="consider">
                            <template scope="scope">
                                <div class="table-row">
                                    <span>{{ scope.row.consider }}%</span>
                                    <el-progress
                                        type="circle"
                                        :width="30"
                                        :stroke-width="4"
                                        :percentage="
                                            (scope.row.consider / 10000) * 100
                                        "
                                        color="#2db8f8"
                                        :show-text="false"
                                        :text-inside="false"
                                    />
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import academicFontData from '@/components/academic_font/academic_font.vue'
import { queryWordTagList } from '@/http/api'

export default {
  components: {
    academicFontData
  },
  data () {
    return {
      cikuList: [
        {
          leftImg: require('@/assets/img/icon-shijuanguanli.png'),
          rightTitle: '单词管理',
          rightDesc: '编辑单词信息',
          icon: 'icon-ziyuan',
          turnUrl: '/academic_Management/academic_font_management/academic_word_list',
          menuTag: '1',
          flag: this.$chnEngStatusCode.academic_word_management
        },
        {
          leftImg: require('@/assets/img/icon-shijuanguanli.png'),
          rightTitle: '词库管理',
          rightDesc: '编辑词库信息',
          icon: 'icon-kecheng',
          menuTag: '2',
          turnUrl: '/academic_Management/academic_font_management/academic_wordgroup_management',
          flag: this.$chnEngStatusCode.academic_wordgroup_management
        }
      ],
      racingToolList: [
        {
          leftImg: require('@/assets/img/icon-shijuanguanli.png'),
          rightTitle: '题型管理',
          rightDesc: '编辑题型信息',
          turnUrl: '/academic_Management/academic_font_management/academic_questionType_management',
          icon: 'icon-bisai',
          menuTag: '3',
          flag: this.$chnEngStatusCode.academic_questionType_management
        },
        {
          leftImg: require('@/assets/img/icon-school.png'),
          rightTitle: '题库管理',
          rightDesc: '编辑题库内容',
          icon: 'icon-diannao',
          turnUrl: '/academic_Management/academic_font_management/academic_question_management',
          menuTag: '4',
          flag: this.$chnEngStatusCode.academic_question_management
        },
        {
          leftImg: require('@/assets/img/icon-diannao.png'),
          rightTitle: '试卷管理',
          rightDesc: '组卷、试卷题管理',
          icon: 'icon-qiehuanmoshi',
          turnUrl: '/academic_Management/academic_font_management/academic_examination_management',
          menuTag: '5',
          flag: this.$chnEngStatusCode.academic_examination_management
        },
        {
          leftImg: require('@/assets/img/icon-diannao.png'),
          rightTitle: '蜂Talk管理',
          rightDesc: '蜂Talk试卷题管理',
          icon: 'icon-tongzhi',
          turnUrl: '/academic_Management/academic_font_management/academic_examination_ftpaper',
          menuTag: '6',
          flag: this.$chnEngStatusCode.academic_examination_fengtalk
        }
      ],
      toolManageList: [{
        leftImg: require('@/assets/img/icon-diannao.png'),
        rightTitle: 'PPT制作',
        rightDesc: '根据excel制作PPT',
        icon: 'icon-file-ppt-fill',
        menuTag: '1',
        flag: this.$chnEngStatusCode.academic_ppt_management,
        turnUrl: 'https://jzy.soullions.com/'
      }, {
        leftImg: require('@/assets/img/icon-diannao.png'),
        rightTitle: '消息反馈',
        rightDesc: '查看消息信息',
        turnUrl: '/academic_Management/academic_font_management/academic_word_feedback',
        icon: 'icon-xiaoxi',
        menuTag: '2',
        flag: this.$chnEngStatusCode.academic_message_feedback
      }],
      p_c_level: this.$userInfo.getDataList(this, 'p_c_level').then(value => { this.p_c_level = value }),
      tags: [
        // '字母个数',
        // '单词拼写',
        // '音节个数',
        // '国际音标',
        // '词频',
        // '词性',
        // '定义',
        // '例句',
        // '年级',
        // '考试',
        // '模考来源',
        // '等级',
        // '类别'
      ],
      marketingRankList: [
        {
          rankStr: 1,
          districtName: 'apple',
          consider: '10000'
        },
        {
          rankStr: 2,
          districtName: 'apple',
          consider: '9000'
        },
        {
          rankStr: 3,
          districtName: 'apple',
          consider: '8000'
        },
        {
          rankStr: 4,
          districtName: 'apple',
          consider: '7000'
        },
        {
          rankStr: 5,
          districtName: 'apple',
          consider: '6000'
        },
        {
          rankStr: 6,
          districtName: 'apple',
          consider: '5500'
        },
        {
          rankStr: 7,
          districtName: 'apple',
          consider: '4000'
        },
        {
          rankStr: 8,
          districtName: 'apple',
          consider: '2000'
        }
      ]
    }
  },
  created () {
    this.getDefaultTags()
  },
  mounted () {
    this.$refs.dataRef.initCharts()
  },
  methods: {
    getDefaultTags () {
      queryWordTagList({ tagDefault: 1 }).then(res => {
        this.tags = res.data
      })
    },
    handleToPage ({ dictValue }) {
      this.$router.push({
        path: '/academic_Management/academic_font_management/academic_font_management',
        query: {
          status: dictValue
        }
      })
    },
    handleToTags (n) {
      console.log(n)
      this.$router.push({
        path: '/academic_Management/tags/list',
        query: {
          n
        }
      })
    },
    racingToolClicked (indexData) {
      this.$router.push(
        {
          path: indexData.turnUrl,
          query: {
            scheduleFlag: indexData.flag
          }
        }
      )
    },
    toolManageClicked (indexData) {
      if (indexData.menuTag === '1') {
        window.open(indexData.turnUrl)
      } else {
        this.$router.push(
          {
            path: indexData.turnUrl,
            query: {
              scheduleFlag: indexData.flag
            }
          }
        )
      }
    },
    format (row, column) {
      // console.log('column', row)
      return () => {
        if (column.label === '频率') {
          return row.consider
        } else if (column.label === '单词量占比') {
          return (row.consider * 100).toFixed(2) + '%'
        }
      }
    }
  }
}
</script>
<style scoped lang="less">
.menu-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .menu-item {
        margin-right: 16px;
        flex: 1;
        color: #fff;
        font-size: 18px;
        cursor: pointer;
    }
}
.table-row {
    display: flex;
    align-items: center;
    height: 40px;
    span {
        display: block;
        width: 80px;
        margin-right: 20px;
    }
}
.table-row1 {
    height: 40px;
    line-height: 40px;
}

.tag {
    height: 32px;
    line-height: 32px;
    border-radius: 32px;
    background-image: linear-gradient(to right, #409eff 40%, #95c5f4);
    color: #fff;
    font-size: 14px;
    padding-left: 42px;
    padding-right: 20px;
    position: relative;
    margin-top: 20px;
    margin-bottom: 10px;
    cursor: pointer;
    .tag-icon {
        width: 34px;
        height: 34px;
        border-radius: 34px;
        background-color: #409eff;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #fff;
        position: absolute;
        left: -2px;
        top: -2px;
        .iconfont {
            font-size: 20px;
        }
    }
}
.tag-define {
    background: transparent;
    border: 2px solid #37b9f6;
    color: #37b9f6;
    height: 28px;
    line-height: 28px;
    .tag-icon {
        color: #fff;
        border-color: #37b9f6;
        top: -4px;
    }
}
</style>
