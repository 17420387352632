<template>
  <div>
    <div class="district-content-backview">
      <el-row>
        <el-col :span="6" v-for="(itemData, index) in districtList" v-bind:key="index">
          <div class="district-itemview">
            <div :id="`main_${index}`" style="width: 100%; height: 200px;"></div>
            <div class="district-desc">
              总数:{{ itemData.allNumber }}所，今年:{{ itemData.thisYear }}所,
              新增:{{ itemData.addNumber }}所
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  created () {
    this.$nextTick(() => {
      this.initCharts()
    })
  },
  methods: {
    initCharts () {
      for (var i = 0; i < this.districtList.length; i++) {
        var itemData = this.districtList[i]
        var myChart = echarts.init(document.getElementById('main_' + i)) // 循环创建每个饼图的配置项和数据
        var option = {
          // color: ['#5526c7', '#449f76', '#f66c98', '#f8ae19', '#2db8f8', '#fc8452', '#9a60b4', '#ea7ccc', '#5470c6'],
          // color: ['#d6bbf3', '#c3fae1', '#fcd3e1', '#fcedba', '#cdedfd', '#fc8452', '#9a60b4', '#ea7ccc', '#5470c6'],
          color: ['#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc', '#5470c6'],
          title: {
            text: '{b|' + itemData.districtName + '}', // '{b|10000000}',
            // subtext: '{a|' + '总数:' + itemData.allNumber + '所}',
            subtext: `{a|${itemData.allNumber}}`,
            // subtext: '{a|' + '总数：' + itemData.allNumber + '所，' + '今年：' + itemData.thisYear + '所' + '新增：' + itemData.addNumber + '所',
            subtextStyle: {
              rich: {
                a: {
                  fontSize: '28',
                  color: '#000'
                }
              }
            },
            textStyle: {
              rich: {
                b: {
                  fontSize: '14'
                }
              }
            },
            left: 'center',
            top: '70px'
          },
          tooltip: {
            trigger: 'item',
            formatter: '{b} : {c} ({d}%)'
          },
          series: [
            {
              name: this.districtList[i].name,
              type: 'pie',
              label: {
                show: false
              },
              radius: ['66%', '82%'],
              // center: ['50%', '50%'],
              data: this.districtList[i].data,
              itemStyle: {
                emphasis: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        }

        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(option)
      }
    }
  },
  data () {
    return {
      districtList: [
        {
          districtName: '通识字库',
          allNumber: '100',
          thisYear: '50',
          addNumber: '50',
          data: [
            { value: 335, name: '总数' },
            { value: 310, name: '新增' },
            { value: 234, name: '今年' }
          ]
        },
        {
          districtName: '小初组字库',
          allNumber: '100',
          thisYear: '50',
          addNumber: '50',
          data: [
            { value: 335, name: '总数' },
            { value: 310, name: '今年' },
            { value: 234, name: '新增' }
          ]
        },
        {
          districtName: '小中组字库',
          allNumber: '100',
          thisYear: '50',
          addNumber: '50',
          data: [
            { value: 335, name: '总数' },
            { value: 310, name: '今年' },
            { value: 234, name: '新增' }
          ]
        },
        {
          districtName: '小高组字库',
          allNumber: '100',
          thisYear: '50',
          addNumber: '50',
          data: [
            { value: 335, name: '总数' },
            { value: 310, name: '今年' },
            { value: 234, name: '新增' }
          ]
        },
        {
          districtName: '初中组字库',
          allNumber: '100',
          thisYear: '50',
          addNumber: '50',
          data: [
            { value: 335, name: '总数' },
            { value: 310, name: '今年' },
            { value: 234, name: '新增' }
          ]
        },
        {
          districtName: '高中组字库',
          allNumber: '100',
          thisYear: '50',
          addNumber: '50',
          data: [
            { value: 335, name: '总数' },
            { value: 310, name: '今年' },
            { value: 234, name: '新增' }
          ]
        },
        {
          districtName: '挑战字库',
          allNumber: '100',
          thisYear: '50',
          addNumber: '50',
          data: [
            { value: 335, name: '总数' },
            { value: 310, name: '今年' },
            { value: 234, name: '新增' }
          ]
        }
      ]
    }
  }
}
</script>

<style scoped>
.district-content-backview {
  width: 90%;
  margin-left: 5%;
}
.district-itemview {
  width: 200px;
  margin: 0 auto 40px;
  display: flex;
  flex-direction: column;
}
.district-desc {
  width: 100%;
  font-size: 12px;
  color: rgb(49, 49, 49);
}
</style>
